import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of, pipe} from 'rxjs';
import {Cart} from '../models/cart.model';
import {HttpService} from './http.service';
import {CurrencyUnit} from "../models/currnecy-unit.model";
import {Price} from "../models/price.model";
import {CurrencyValue} from "../models/currency-value.model";
import {CustomerOrder} from "../classes/customer-order";
import {paymentResult} from "../classes/class";
import {PaymentInfo} from "../models/payment-info.model";
import {Bold} from "@ckeditor/ckeditor5-basic-styles";
import {OrderHeader} from "../models/order-header.model";
import {MatSnackBar} from "@angular/material/snack-bar";
import {catchError, map} from "rxjs/operators";
import {Util} from "leaflet";
import indexOf = Util.indexOf;
import {OrderBody} from "../models/order-body.model";
import {EMPTY} from "../classes/guid";
import {ExchangeTotalPrices, PriceUnit} from "../interfaces/PriceUnit";

@Injectable({
  providedIn: 'root',
})
export class CartService {
  randomGuid: string = '';
  translations: { [id: string]: string } = {};
  private isLoadingCartsSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public isLoadingCarts$: Observable<boolean> = this.isLoadingCartsSubject.asObservable();

  private isLoadingPaymentsSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public isLoadingPayments$: Observable<boolean> = this.isLoadingPaymentsSubject.asObservable();

  private isLoadingSumPaymentsSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public isLoadingSumPayments$: Observable<boolean> = this.isLoadingSumPaymentsSubject.asObservable();

  carts: Cart[] = [];
  payments: PaymentInfo[] = [];
  currencies: CurrencyUnit[] = [];

  CurrencyValues: CurrencyValue[] = [];
  customerCurrencyUnit: CurrencyUnit | null = null;

  totalPriceList: PriceUnit[] = [];
  collegeTotalPriceList: PriceUnit[] = [];
  meetingTotalPriceList: PriceUnit[] = [];

  totalPaymentList: PriceUnit[] = [];
  totalPaymentExchange: number | null = null;

  exchangeTotalPrices: ExchangeTotalPrices = {total: null, collegeTotal: null, meetingTotal: null};

  order: OrderHeader | null = null;
  selectedCurrency: CurrencyUnit | null = null;
  selectedCurrencyId: string | null = null;

  //--------------------------

  constructor(private api: HttpService,
              private snackBar: MatSnackBar) {
    this.translations['SOME_THING_WRONG_MSG'] = "Something went wrong.";
  }

  initializeService() {
    this.randomGuid = localStorage.getItem('RandomGuid') ?? '';

    this.currencies = JSON.parse(
      localStorage.getItem('currencyUnits') ?? '[{}]'
    );
    this.customerCurrencyUnit = JSON.parse(localStorage.getItem('currencyUnit') ?? 'null');
    this.getAllCurrencyValue();
    this.loadCartsFromServer();
    this.loadPaymentHistoryFromServer();
    this.loadTotalPaymentFromServer();
  }

  getEslCourseCarts() {
    return this.carts.filter(q => q.cartType === 1 && q.isEsl && q.course);
  }

  getMeetingCarts() {
    return this.carts.filter(q => q.cartType === 2 && q.meeting);
  }

  getNotEslCourseCarts() {
    return this.carts.filter(q => q.cartType === 1 && !q.isEsl && q.course);
  }

  private clearData_cart() {
    this.totalPriceList = [];
    this.collegeTotalPriceList = [];
    this.meetingTotalPriceList = [];
    this.exchangeTotalPrices = {total: null, collegeTotal: null, meetingTotal: null};
  }

  private clearData_payment() {
    this.totalPaymentExchange = null;
    this.totalPaymentList = [];
    this.payments = [];
  }

  loadCartsFromServer(): void {
    this.isLoadingCartsSubject.next(true);
    this.clearData_cart();

    this.api
      .request<Cart[]>('Cart', 'GetCartsForProgramSearch', {Guid2: this.randomGuid}, 'customer', 'GET')//{Guid2: this.randomGuid}
      .subscribe((res) => {
        this.carts = res;
        this.processCarts();

        this.isLoadingCartsSubject.next(false);
      });

  }


  private processCarts(): void {

    if (this.carts && this.carts.length > 0) {

      this.carts.forEach((q) => {
        q.currencyUnit = this.getCurrency(q.currencyUnitId);

        q.totalPrice = null;
        q.exchangeSumPrice = null;
      });

      this.setActiveCurrency();
      this.processEslCarts();
      this.processNotEslCourseCarts();
      this.processMeetingCarts();
    }
  }

  findMostFrequentCurrency(): string | null {
    // Step 1: Create a map to count the occurrences of each currency
    const currencyCount: { [key: string]: number } = {};
    let carts = this.carts.filter(q => q.selectedToPay);

    if (carts.length === 0) return null;

    carts.forEach(cart => {
      const currency = cart.currencyUnitId;
      if (currencyCount[currency]) {
        currencyCount[currency]++;
      } else {
        currencyCount[currency] = 1;
      }
    });

    // Step 2: Find the currency with the highest occurrence
    let mostFrequentCurrency: string | null = null;
    let maxCount = 0;

    for (const currency in currencyCount) {
      if (currencyCount[currency] > maxCount) {
        maxCount = currencyCount[currency];
        mostFrequentCurrency = currency;
      }
    }

    return mostFrequentCurrency;
  }

  private setActiveCurrency() {

    this.selectedCurrencyId = this.findMostFrequentCurrency();//carts.filter(q => q.selectedToPay));
    this.selectedCurrency = this.getCurrency(this.selectedCurrencyId);
    if (this.selectedCurrencyId) {
      this.carts.forEach(cart => {
        cart.isSelectableToPay = cart.currencyUnitId === this.selectedCurrencyId;
        cart.selectedToPay = cart.selectedToPay && cart.isSelectableToPay;
      });
      this.saveSelectedCartsState();
    } else {
      this.carts.forEach(cart => {
        cart.isSelectableToPay = true;
      })
    }
  }

  private processEslCarts(): void {

    this.getEslCourseCarts().map(q => {
      this.setCurrencyForPrice(q.weekPrice);
      this.setCurrencyForPrice(q.matPrice);
      this.setCurrencyForPrice(q.regPrice);

      if (q.weekPrice && q.regPrice && q.matPrice) {
        let weekAmount: number = (q.noWeek ?? 1) * (q.weekPrice.amount === -1 ? 0 : q.weekPrice.amount);
        let matAmount = q.matPrice.amount === -1 ? 0 : q.matPrice.amount;
        let regAmount = q.regPrice.amount === -1 ? 0 : q.regPrice.amount;
        q.weekPrice.eslWeek_sumAmount = weekAmount;

        q.totalPrice = q.price;
        //client-side calculation
        // q.totalPrice = weekAmount + matAmount + regAmount;
        // q.isChangePrice = q.totalPrice !== q.price;
        if (q.currencyUnit!.test_exchangeFactor) {
          q.hasCurrencyExchangeFactor = true;
          q.exchangeSumPrice = weekAmount * (q.weekPrice.exchangeFactor ?? 0);
          q.exchangeSumPrice += matAmount * (q.matPrice.exchangeFactor ?? 0);
          q.exchangeSumPrice += regAmount * (q.regPrice.exchangeFactor ?? 0);
        } else q.exchangeSumPrice = null;

        if (q.selectedToPay) {
          this.exchangeTotalPrices.total = (q.exchangeSumPrice ?? 0) + (this.exchangeTotalPrices.total ?? 0);
          this.addPriceToPayPriceList(this.totalPriceList, q.totalPrice, q.currencyUnit!);
        }
      }
    });
  }

  private processNotEslCourseCarts() {

    this.getNotEslCourseCarts().forEach(q => {
      this.setCurrencyForPrice(q.applicationFee);

      let applicationFeeAmount = !q.applicationFee?.amount || q.applicationFee?.amount === -1 ? 0 : q.applicationFee?.amount;
      q.totalPrice = q.price;
      //client side calculation
      //q.totalPrice = applicationFeeAmount;
      //q.isChangePrice = q.totalPrice !== q.price;

      if (q.currencyUnit!.test_exchangeFactor) {
        q.hasCurrencyExchangeFactor = true;
        q.exchangeSumPrice = applicationFeeAmount * (q.applicationFee?.exchangeFactor ?? 0); // 0 or 1?????
      } else {
        q.exchangeSumPrice = null; // total doesn't contain all carts. So it is not valid
      }
      if (q.selectedToPay) {
        this.exchangeTotalPrices.collegeTotal = (q.exchangeSumPrice ?? 0) + (this.exchangeTotalPrices.collegeTotal ?? 0);
        this.exchangeTotalPrices.total = (q.exchangeSumPrice ?? 0) + (this.exchangeTotalPrices.total ?? 0);
        this.addPriceToPayPriceList(this.collegeTotalPriceList, q.totalPrice, q.currencyUnit!);
        this.addPriceToPayPriceList(this.totalPriceList, q.totalPrice, q.currencyUnit!);
      }
    });
  }

  private processMeetingCarts() {

    this.getMeetingCarts().forEach(q => {
      //q.currencyUnit = this.getCurrency(q.currencyUnitId);
      this.setCurrencyForPrice(q.meeting!.price);
      let meetingAmount = !q.meeting!.price?.amount || q.meeting!.price?.amount === -1 ? 0 : q.meeting!.price?.amount;
      q.totalPrice = q.price;

      // let foundExchange = this.CurrencyValues.find(x => x.toCurrencyUnitId === this.customerCurrencyUnit?.currencyUnitId
      //   && x.fromCurrencyUnitId === q.currencyUnitId);
      // if (foundExchange) {
      //   q.currencyUnit!.test_exchangeFactor = foundExchange?.amount ?? null;
      //   //item.exchangeFactor = foundExchange?.amount ?? null;
      // }

      if (q.meeting!.price?.currencyUnit?.test_exchangeFactor) {
        q.hasCurrencyExchangeFactor = true;
        q.exchangeSumPrice = q.meeting!.price.amount * q.meeting!.price?.currencyUnit?.test_exchangeFactor;
      } else q.exchangeSumPrice = null;

      if (q.selectedToPay) {
        this.exchangeTotalPrices.meetingTotal = (q.exchangeSumPrice ?? 0) + (this.exchangeTotalPrices.meetingTotal ?? 0);
        this.exchangeTotalPrices.total = (q.exchangeSumPrice ?? 0) + (this.exchangeTotalPrices.total ?? 0);
        this.addPriceToPayPriceList(this.meetingTotalPriceList, q.totalPrice, q.currencyUnit!);
        this.addPriceToPayPriceList(this.totalPriceList, q.totalPrice, q.currencyUnit!);
      }
    });
  }

  private calculateSumPrice(res: Cart[]): void {
    //total price all carts
    let esl = res.find(q => q.cartType === 1 && q.isEsl && q.selectedToPay);
    //let esl = this.getEslCourseCarts().filter(q=>q.selectedToPay);// res.find(q => q.cartType === 1 && q.isEsl && q.selectedToPay);

    this.totalPriceList = this.collegeTotalPriceList;
    this.exchangeTotalPrices.total = this.exchangeTotalPrices.collegeTotal;

    // this.meetingTotalPriceList.forEach(q=>{
    //   this.addPriceToPayPriceList(this.totalPriceList, q.amount!, q.currencyUnit!);
    // });

    if (esl) {
      if (esl.exchangeSumPrice && this.exchangeTotalPrices.collegeTotal) {
        this.exchangeTotalPrices.total = esl.exchangeSumPrice + this.exchangeTotalPrices.collegeTotal;
      } else {
        this.exchangeTotalPrices.total = null;
      }

      let foundPrice = this.totalPriceList.find(x => x.currencyUnit === res.find(q => q.isEsl)?.currencyUnit);
      if (foundPrice && foundPrice.amount) {
        foundPrice.amount += (esl.totalPrice ?? 0);// this.eslCarts[0].totalPrice ?? 0;
      } else
        this.totalPriceList.push({
          amount: esl.totalPrice,
          currencyUnit: esl.currencyUnit,
        });
    }
  }

  isCourseApplicable(courseId: string, isEsl: boolean): 'alreadyExist' | 'alreadyHaveEsl' | 'alreadyHaveMaxCollege' | 'applicable' | '' | 'ok' {
    if (this.carts.some(q => q.foreignId === courseId)) {
      return 'alreadyExist';
    }
    if (isEsl && this.getEslCourseCarts().length > 0) {
      return 'alreadyHaveEsl';
    }

    if (!isEsl && this.getNotEslCourseCarts().length >= 3) {
      return 'alreadyHaveMaxCollege';
    }
    return 'applicable';
  }

  addCart(newCart: Cart) {
    this.api
      .request('Cart', 'PutFromProgramSearch', newCart, 'customer', 'POST')
      .subscribe((res: any) => {
        this.loadCartsFromServer();
      });
  }

  removeCart(cart: Cart) {
    // cart.randGuid = this.randomGuid;
    this.api
      .request('Cart', 'Delete', cart, 'customer', 'DELETE')
      .subscribe((res: any) => {
        // this.lsRemoveCart(cart.cartId);
        this.loadCartsFromServer();
      });
  }

  removeCartObs(cart: Cart) {
    // cart.randGuid = this.randomGuid;
    return this.api
      .request('Cart', 'Delete', cart, 'customer', 'DELETE');
    // .subscribe((res: any) => {
    //   // this.lsRemoveCart(cart.cartId);
    //   this.loadCartsFromServer();
    // });
  }

  clearCarts(): void {
    this.carts = [];
    //this.cartItemsSubject.next([]);
  }

//-------------------------------

  addPriceToPayPriceList(priceList: PriceUnit[], priceAmount: number | null, currency: CurrencyUnit | null) {
    if (priceAmount && currency) {
      let found = priceList.find(x => x.currencyUnit === currency);
      //if (found && found.amount) {
      if (found) {
        found.amount = priceAmount === -1 || !priceAmount ? 0 : priceAmount + (found.amount ?? 0);
      } else priceList.push({
        amount: priceAmount,
        currencyUnit: currency
      });
    }
  }

  subtractPriceFromPayPriceList(priceList: PriceUnit[], priceAmount: number, currency: CurrencyUnit) {
    let found = priceList.find(x => x.currencyUnit === currency);
    if (found && found.amount) {
      found.amount -= priceAmount === -1 ? 0 : priceAmount;
      if (found.amount <= 0) {
        priceList.splice(priceList.indexOf(found), 1);
      }
    }
  }

  //-------------------------------------------------
  private getAllCurrencyValue() {
    this.api
      .request<CurrencyValue[]>(
        'CurrencyValue',
        'GetAllRow',
        {},
        'customer',
        'GET'
      )
      .subscribe((q) => {
        this.CurrencyValues = q;
      });
  }


  private setCurrencyForPrice(price: Price | null) {
    if (price?.amount) {
      price.currencyUnit = this.currencies.find((r) => r.currencyUnitId === price?.currencyUnitId)!;
      //this.exchangeCalc(price);
      if (price.currencyUnitId !== this.customerCurrencyUnit?.currencyUnitId) {
        let foundExchange = this.CurrencyValues.find(x => x.toCurrencyUnitId === this.customerCurrencyUnit?.currencyUnitId
          && x.fromCurrencyUnitId === price.currencyUnit!.currencyUnitId);
        if (foundExchange) {

          price.currencyUnit.test_exchangeFactor = foundExchange?.amount ?? null;
          price.exchangeFactor = foundExchange?.amount ?? null;
        }
      }

    }
  }

  getCurrency(currencyUnitId: string | null): CurrencyUnit | null {
    if (!currencyUnitId)
      return null;
    return this.currencies.find(q => q.currencyUnitId === currencyUnitId)!;
  }


//-----------------------------------
  Pay(countryId: string | null, portalId: string | null, portalType: number): Observable<paymentResult<string>> {
    let tempOrder: CustomerOrder = new CustomerOrder();
    tempOrder.cartIds = this.carts.filter(q => q.selectedToPay).map(q => q.cartId);//this.cartToPayList;
    tempOrder.portalId = portalId;
    tempOrder.countryId = countryId === '0' ? EMPTY : countryId;

    return this.api
      .request<paymentResult<string>>('Payment', 'PayOrder', tempOrder, 'customer', 'POST');
  }

  getOrder(countryId: string | null, portalId: string | null, portalType: number): Observable<any> {

    let order: CustomerOrder = new CustomerOrder();
    //order.cartIds = this.cartToPayList;
    //order.cartIds = this.carts.filter(q => q.selectedToPay).map(q => q.cartId);

    order.portalId = portalId;
    order.countryId = countryId;
    if (!this.carts.some(q => q.selectedToPay)) return of({message: 'No cart selected to pay', data: null});//return of(null);
    return this.api.request<any>('Order', 'GetCurrentOrder', order, 'customer', 'POST')
      .pipe(
        map(res => {
          if (res && res.data) {
            res.data.currencyUnit = this.currencies.find(c => c.currencyUnitId === res.data.currencyUnitId)!;
            res.data.orderBodies.forEach((q: OrderBody) => {
              q.currencyUnit = this.currencies.find(c => c.currencyUnitId === q.currencyUnitId)!;
            });
            this.order = res.data;
          }
          return res;
        })
      );
  }


  loadPaymentHistoryFromServer(): void {
    this.isLoadingPaymentsSubject.next(true);
    this.payments = [];

    this.api
      .request<PaymentInfo[]>(
        'Payment',
        'GetAllPayments',
        {},
        'customer',
        'GET'
      )
      .subscribe((q) => {
        this.payments = q;
        this.payments.forEach(q => {
          q.paidCurrencyUnit = this.getCurrency(q.paidCurrencyUnitId);
          q.originalCurrencyUnit = this.getCurrency(q.originalCurrencyUnitId);
          if (q.orderHeader) {
            q.orderHeader.currencyUnit = this.getCurrency(q.orderHeader?.currencyUnitId);
            q.orderHeader.orderBodies.forEach(ob => ob.currencyUnit = this.getCurrency(ob.currencyUnitId))
          }
        });
        this.isLoadingPaymentsSubject.next(false);
      });
  }

  loadTotalPaymentFromServer() {
    this.isLoadingSumPaymentsSubject.next(true);
    this.clearData_payment();

    this.api
      .request<PaymentInfo[]>('Payment', 'GetTotalPayment', {}, 'customer', 'GET')
      .subscribe(res => {
        //this.clearData_payment();
        res.forEach((item) => {

          item.paidCurrencyUnit = this.getCurrency(item.paidCurrencyUnitId);// this.currencies.find(c => c.currencyUnitId === item.paidCurrencyUnitId)!;
          if (item.paidCurrencyUnitId !== this.customerCurrencyUnit?.currencyUnitId) {
            let foundExchange = this.CurrencyValues.find(x => x.toCurrencyUnitId === this.customerCurrencyUnit?.currencyUnitId
              && x.fromCurrencyUnitId === item.paidCurrencyUnitId);
            if (foundExchange) {
              item.paidCurrencyUnit!.test_exchangeFactor = foundExchange?.amount ?? null;
              item.exchangeFactor = foundExchange?.amount ?? null;
            }
          }

          this.addPriceToPayPriceList(this.totalPaymentList, item.paidAmount, item.paidCurrencyUnit);
          if (item.exchangeFactor) {
            this.totalPaymentExchange = item.paidAmount! * item.exchangeFactor + (this.totalPaymentExchange ?? 0);
          } else {
            this.totalPaymentExchange = null; // total doesn't contain all items. So it is not valid
          }
        });
        this.isLoadingSumPaymentsSubject.next(false);
      });
  }

  saveSelectedCartsState() {
    const extractedArray = this.carts.map(item => ({
      cartId: item.cartId,
      selectedToPay: item.selectedToPay
    }));
    this.api.request<any>('Cart', 'PutChangeSelectedCartsToPay', extractedArray, 'customer', 'POST').subscribe({});
  }

  enableCartsToSelect() {
    if (!this.carts.some(q => q.selectedToPay)) {
      this.carts.forEach(q => {
        q.isSelectableToPay = true;
      });
    }
  }

  resetSelectableCarts() {
    if (this.carts.filter(q => q.selectedToPay).length === 1) {
      this.setActiveCurrency();
    }
  }
}
